import { ButtonGroup, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StyledButton from '../styledComponents/StyledButton';
import useAsync from '../common/useAsync';

const buttons: { [x: string]: string } = {
  '/': 'my-loads-button',
  '/loads': 'my-loads-button',
  '/carriers': 'my-carriers-button',
  '/customers': 'my-customers-button',
  '/search': 'search-button',
};

const routes: { [x: string]: string } = {
  'my-loads-button': '/',
  'my-carriers-button': '/carriers',
  'my-customers-button': '/customers',
  'search-button': '/search',
};

function ActionButtons() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(buttons[location.pathname]);
  const {
    value,
    status,
  } = useAsync<boolean>({
    endpoint: 'loads/canViewSearch',
  });

  const onButtonClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setActiveButton(e.currentTarget.id);

    navigate(routes[e.currentTarget.id]);
  };

  useEffect(() => {
    setActiveButton(buttons[location.pathname]);
  }, [location]);

  if (status === 'pending') {
    return <CircularProgress color="inherit" />;
  }

  return (
    <div>
      <ButtonGroup
        id="tab-navigation-container"
        variant="contained"
        aria-label="outlined primary button group"
        sx={{
          marginTop: 2, marginBottom: 2, boxShadow: 'none', border: '1px solid #ccc',
        }}
      >
        <StyledButton
          onClick={onButtonClicked}
          id="my-loads-button"
          style={{
            color: activeButton === 'my-loads-button' ? '#fff' : 'inherit',
            backgroundColor: activeButton === 'my-loads-button' ? '#0093d0' : 'inherit',
          }}
        >
          My Loads
        </StyledButton>
        <StyledButton
          onClick={onButtonClicked}
          id="my-carriers-button"
          style={{
            color: activeButton === 'my-carriers-button' ? '#fff' : 'inherit',
            backgroundColor: activeButton === 'my-carriers-button' ? '#0093d0' : 'inherit',
          }}
        >
          Carriers
        </StyledButton>
        <StyledButton
          onClick={onButtonClicked}
          id="my-customers-button"
          style={{
            color: activeButton === 'my-customers-button' ? '#fff' : 'inherit',
            backgroundColor: activeButton === 'my-customers-button' ? '#0093d0' : 'inherit',
          }}
        >
          My Customers
        </StyledButton>
        {value && (
          <StyledButton
            onClick={onButtonClicked}
            id="search-button"
            style={{
              color: activeButton === 'search-button' ? '#fff' : 'inherit',
              backgroundColor: activeButton === 'search-button' ? '#0093d0' : 'inherit',
            }}
          >
            Search
          </StyledButton>
        )}
      </ButtonGroup>
    </div>
  );
}

export default ActionButtons;
