import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import axios, { AxiosResponse } from 'axios';
import { useMsal } from '@azure/msal-react';
import apiBaseUrl from './constants';
import { applicationSettings } from './authConfig';

interface IuseAsyncProps {
  immediate?: boolean,
  endpoint: string,
  verb?: 'get' | 'put' | 'post' | 'delete' | 'patch',
  additionalRouteParameters?: string,
}

const axiosInstance = axios.create();

const useAsync = <T, E = string>(props: IuseAsyncProps) => {
  const {
    immediate = true, endpoint, verb = 'get', additionalRouteParameters = '',
  } = props;
  const { instance, accounts } = useMsal();
  const [status, setStatus] = useState<'idle' | 'pending' | 'success' | 'error'>('idle');
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<E | null>(null);

  axiosInstance.defaults.baseURL = useMemo(() => `${apiBaseUrl}/${endpoint}`, [endpoint]);

  const execute = useCallback(() => {
    setStatus('pending');
    setValue(null);
    setError(null);

    (async () => {
      try {
        const token = await instance.acquireTokenSilent({
          scopes: applicationSettings.scopes,
          account: accounts[0],
        });

        const response = await axiosInstance[verb]<AxiosResponse<T>>(additionalRouteParameters, {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        });

        setValue(response.data as unknown as T);
        setStatus('success');
        /* eslint-disable @typescript-eslint/no-explicit-any */
      } catch (ex: any) {
        setStatus('error');
        setError(ex.message);
      }
    })();
  }, [accounts, additionalRouteParameters, instance, verb]);

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return {
    execute,
    status,
    value,
    error,
  };
};

export default useAsync;
